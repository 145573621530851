@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}
#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main input[type="button"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
#main input[type="cancel"]:not(.red),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white) {
  background-color: @color-primary--dark;
  &:focus,
  &:hover {
    background-color: @color-primary--darker;
  }
}
.not-for-ansatte {
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-neutral--light;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-primary--dark;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--darker;
      }
    }
  }
  // .not-for-ansatte {
  //   button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
  //   button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red)
  //     input[type="button"]:not(.red),
  //   input[type="submit"]:not(.submit-comment-button):not(.red),
  //   input[type="cancel"]:not(.red),
  //   .header-search-expand,
  //   button {
  //     background-color: @color-primary--dark !important;
  //     .transition(all, 0.5s, ease-in);
  //     &:focus,
  //     &:hover {
  //       background-color: darken(spin(@color-primary--dark, -5), 10%) !important;
  //     }
  //   }
  //   .header-search {
  //     top: 18px;
  //     z-index: 10;
  //     input[type="text"] {
  //       background: @color-neutral--light;
  //       .placeholderColor(@color-dark, @color-dark) !important;
  //       color: @color-neutral--dark;
  //     }
  //   }
  #head-wrapper {
    background: @color-light url("../images/banner.png") no-repeat scroll right
      top;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png")
        no-repeat scroll 90% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 150px;
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        a {
          color: @color-dark;
          background-color: @color-light;
          margin-top: -5px;
          padding: 6px 30px 6px 15px;
          border-radius: 3px;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        color: #2b2b2b;
        display: block;
        font-size: 24px;
        height: 114px;
        left: 0;
        position: absolute;
        top: 30px;
        width: 970px;
        .title1 {
          font-size: 24px;
          left: 385px;
          line-height: 1em;
          position: absolute;
          top: 35px;
        }
        .title2 {
          font-size: 16px;
          left: 385px;
          position: absolute;
          top: 65px;
        }
        .uib-logo {
          left: 0px;
          position: absolute;
          top: 0px;
        }
        .uio-logo {
          left: 107px;
          position: absolute;
          top: 0px;
        }
        .ntnu-logo {
          left: 220px;
          position: absolute;
          top: 35px;
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-dark;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child {
    border-bottom: 1px @color-neutral--air solid;
    a {
      color: @color-link;
      background-color: @color-neutral--light;
      border-top: none !important;
      background: rgba(0, 0, 0, 0)
        url("/vrtx/decorating/resources/dist/images/li-arrow.png") no-repeat
        scroll 10px 23px;
    }
  }
  a {
    &.vrtx-marked {
      background-color: @color-primary !important;
      color: @color-dark;
      border-top: none !important;
    }
    &:hover,
    &:focus {
      background-color: @color-neutral--light;
    }
  }
  span::before,
  a::before {
    content: " " !important;
  }
}

table th {
  font-weight: bold;
}

#vrtx-additional-content .vrtx-frontpage-box {
  border-bottom: 4px solid #eaeaea;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg")
    no-repeat 0 3px !important;
}

.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-primary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0)
        url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: none @color-light;
      height: 178px;
      #head {
        height: 130px;
        #header {
          img {
            padding: 0 10px 10px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #vrtx-additional-content .vrtx-frontpage-box {
    border-bottom: none;
  }
}

/* Mixin */

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

@color-primary : #A0C5ED;
@color-primary--dark : #2A78CB;
@color-primary--darker : #1F5A98;
@color-link : #2771bb;

// Neutral colors
@color-neutral--dark : #2b2b2b;
@color-neutral : #949494;
@color-neutral--light : #EAEDEE;
@color-neutral--air : #e5e5e5;
@color-lines : #e4e4e4;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #000000;
@footer-color : @color-neutral--light;
